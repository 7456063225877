<template>
  <div class="BasicInfo_wrapper">
    <el-form ref="baseForm" :rules="rules" class="mt20" :model="baseInfo" label-width="130px">
      <el-form-item label="活动名称：" prop="actName">
        <el-input placeholder="请输入活动名称 " v-model="baseInfo.actName" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="活动时间：" prop="timeRange">
        <el-date-picker
          v-model="baseInfo.timeRange"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptionsStart"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="活动区域：" required class="province">
        <ul class="fee-ul">
          <li class="f v" v-for="(item,ii) in baseInfo.areaList" :key="ii" style="margin:10px 0 0;">
            <div class="f fvc">
              <!-- <v-distpicker
                :province="item.province"
                :city="item.city"
                :area="item.county"
                @selected="(data) => onSelected(data,ii)"
              ></v-distpicker>-->
              <v-distpicker
                :province="item.province"
                :city="item.city"
                @province="(data) => onChangeProvince(data,ii)"
                @city="(data) => onChangeCity(data,ii)"
                :placeholders="{ province: '全部', city: '全部' }"
                hide-area
              ></v-distpicker>
              <!-- <i class="el-icon-circle-close" @click="delStoreFee(item,ii)"></i> -->
            </div>
          </li>
        </ul>
        <div class="form_tips">
          （不在活动区域
          <span class="color_E6A23C">[省市]</span> 的用户，无法参与活动，请谨慎设置）
        </div>
        <el-button @click="addStoreFee" type="primary">添 加 活 动 区 域</el-button>
      </el-form-item>
      <el-form-item label="活动海报图：" prop="poster" class="up_poster">
        <label for="brand-logo" class="el-upload el-upload--picture-card">
          <input
            type="file"
            ref="brandlogo"
            hidden
            id="brand-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage"
          />
          <img v-if="baseInfo.poster" :src="baseInfo.poster" style="width:142px;height:142px;" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </label>
        <span class="ml10 form_tips">建议尺寸750x876 px，支持JPG，PNG，JPEG，GIF格式，限制大小4M内</span>
      </el-form-item>
      <el-form-item label="投诉电话：">
        <el-input
          placeholder="请输入投诉电话"
          v-model="baseInfo.complainMobile"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="直播开始时间：">
        <el-date-picker
          v-model="baseInfo.liveStartTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="开播时间"
          :picker-options="pickerOptionsStart"
        ></el-date-picker>
        <div class="form_tips">（到达直播开始时间后，活动页【预约直播】入口将变更为【观看直播】）</div>
      </el-form-item>
      <el-form-item label="直播地址：">
        <el-input placeholder="请填写直播页URL" v-model="baseInfo.liveUrl"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="submitInfo" type="primary">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

export default {
  name: "BasicInfo_Children", // 基本信息
  components: {
    VDistpicker,
  },
  props: {
    activityID: String,
  },
  data() {
    return {
      upload: null, // 上传对象
      baseInfo: {
        id: "",
        actName: "", // 活动名称
        timeRange: [],
        actStartTime: "", // 活动开始时间
        actEndTime: "", // 活动结束时间
        // 活动区域列表
        areaList: [
          {
            city: "",
            province: "",
          },
        ],
        poster: "", // 活动海报图url
        complainMobile: "", // 投诉电话
        liveStartTime: "", // 直播开始时间
        liveUrl: "", // 直播地址
      },
      rules: {
        actName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        poster: [
          { required: true, message: "请添加活动海报图", trigger: "blur" },
        ],
        timeRange: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
        areaList: [
          { required: true, message: "请选择活动区域", trigger: "blur" },
        ],
      },
      pickerOptionsStart: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取活动信息
    async getActivityDetails() {
      try {
        const { data } = await ActivityAjax.getActivityDetails(this.activityID);
        this.baseInfo.timeRange = [];
        this.baseInfo.timeRange.push(data.actStartTime, data.actEndTime);
        this.baseInfo = Object.assign(this.baseInfo, data);
        // 因组件会在获取完数据并渲染第一个地址时触发省的选择方法，导致列表第一个组件后面市区不显示，所以塞入一个空对象并隐藏，使全部地区可渲染，提交时删除该空对象即可
        this.baseInfo.areaList.unshift({
          city: "",
          county: "",
          province: "",
        });

        this.$bus.$emit("actName", this.baseInfo.actName);
        this.$bus.$emit("actUrl", this.baseInfo.actUrl);
        this.$bus.$emit("qrcodePoster", this.baseInfo.actPoster);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.baseInfo.poster = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // onSelected(data, index) {
    //   this.baseInfo.areaList[index] = {
    //     province: data.province.value,
    //     provinceId: data.province.code,
    //     city: data.city.value,
    //     cityId: data.city.code,
    //     county: data.area.value,
    //     countyId: data.area.code,
    //   };
    // },
    onChangeProvince(data, index) {
      this.baseInfo.areaList[index].province = data.value;
      this.baseInfo.areaList[index].provinceId = data.code;
      this.baseInfo.areaList[index].city = "全部";
      this.baseInfo.areaList[index].cityId = null;
      if (data.value == "全部") {
        this.baseInfo.areaList[index].province = "全部";
        this.baseInfo.areaList[index].provinceId = null;
        this.baseInfo.areaList[index].city = "全部";
        this.baseInfo.areaList[index].cityId = null;
      }
    },
    onChangeCity(data, index) {
      this.baseInfo.areaList[index].city = data.value;
      this.baseInfo.areaList[index].cityId = data.code;
      if (data.value == "全部") {
        this.baseInfo.areaList[index].city = "全部";
        this.baseInfo.areaList[index].cityId = null;
      }
    },
    // onChangeArea(data, index) {
    //   this.baseInfo.areaList[index].county = data.value;
    //   this.baseInfo.areaList[index].countyId = data.code;
    //   if (data.value == "区") {
    //     this.baseInfo.areaList[index].county = null;
    //     this.baseInfo.areaList[index].countyId = null;
    //   }
    // },
    checkHTTP(val) {
      return /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(
        val
      );
    },
    submitInfo() {
      this.$refs.baseForm.validate(async (valid) => {
        try {
          if (valid) {
            var confirmok = true;
            let act = JSON.parse(JSON.stringify(this.baseInfo));
            act.areaList.splice(0, 1);
            console.log(act.areaList);
            var length = act.areaList.length;
            act.areaList.forEach((item) => {
              if (!item.provinceId && length > 1) {
                confirmok = false;
              }
            });

            if (!confirmok) {
              this.$message.warning("请选择活动地区");
              return;
            }
            if (act.liveUrl && this.checkHTTP(act.liveUrl) == false) {
              this.$message.warning("请填写正确的直播页URL");
              return;
            }

            act.actStartTime = act.timeRange[0];
            act.actEndTime = act.timeRange[1];
            // delete act.timeRange;
            await ActivityAjax.postActivitySave(act);
            this.$message.success("保存成功");
            // await this.getActivityDetails();
          } else {
            return false;
          }
        } catch (error) {
          this.$message.error(error);
        }
      });
    },
    addStoreFee() {
      var self = this;
      var item = {
        city: "",
        province: "",
      };
      if (this.baseInfo.areaList.length > 100) {
        this.$message.warning("最多选择100个区域");
      } else {
        this.baseInfo.areaList.push(item);
      }
    },
    delStoreFee(item, index) {
      this.baseInfo.areaList.splice(index, 1);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getActivityDetails();
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.BasicInfo_wrapper {
  .fee-ul .el-icon-circle-close {
    font-size: 20px;
    margin: 0 0 0 10px;
    color: #f56c6c;
    cursor: pointer;
  }

  .fee-ul li:nth-child(1) {
    display: none;
  }
  .fee-ul li:nth-child(2) .el-icon-circle-close {
    display: none;
  }
  .el-tabs__content {
    .el-form-item {
      &.province {
        .el-form-item__content {
          div {
            margin-right: 10px;
          }
          .el-input {
            width: 200px;
          }
        }
      }
      &.up_poster {
        .el-form-item__content {
          display: flex;
          align-items: center;
          .el-upload--text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 178px;
            height: 178px;
          }
        }
      }
      .el-input {
        width: 400px;
      }
    }
    .left_advertising {
      & > div {
        margin-top: 12px;
        .el-upload--text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 178px;
          height: 178px;
        }
      }
    }
    .nav_settings {
      .cell {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .data_optimization {
      // margin: 0 20px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 2;
      }
      ul {
        li {
          line-height: 1.5;
        }
      }
    }
  }
  .form_tips {
    color: #888;
    font-size: 12px;
  }
}
</style>
